import Component from 'vue-class-component';
import {Inject} from 'vue-property-decorator';
import {ActionNavigationItems} from '../../config/action-navigation-items.type';
import Vue from 'vue';
import {ActionTypeService} from "@ncnp-mono/utils";
import {ActionType, ListGroupItemInterface} from "@ncnp-mono/generics";
import html from './actions-overview.component.html';

@Component({
    template: html,
})

export class ActionsOverviewComponent extends Vue {
    @Inject()
    actionTypeService!: ActionTypeService;

    public navigation = {
        shown: true,
        width: 460,
        borderSize: 3,
    };

    public navigationItems: ActionNavigationItems = {
        documents: {
            title: 'Te koppelen acties',
            active: false,
            icon: 'mdi-file-document',
            children: [],
        },
    };

    public beforeMount(): void {
        this.actionTypeService.getAllActionTypes().then(actionTypes => {
            this.navigationItems.documents.children = [
                ...actionTypes.map(actionType => {
                    return ActionsOverviewComponent.mapActionType(actionType);
                }),
            ];
        });
    }

    private static mapActionType(actionType: ActionType): ListGroupItemInterface {
        return {
            title: actionType.name,
            to: {
                name: 'edit-action',
                params: {
                    actionType: actionType.id.toString(),
                },
            },
        };
    }
}
